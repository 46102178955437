import 'selectize/dist/js/selectize.min.js';
import 'selectize/dist/css/selectize.css';

const selectize = () => {
    $('.selectize').selectize({
        create: true,
        createOnBlur: true,
        sortField: 'text',
        maxItems: null,
        plugins: ['remove_button']
    });
};

export { selectize };
