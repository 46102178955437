
function enableDatetimeFields($scope) {
  function clearDate(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $datetimeField = $(event.target).closest('.date-input').find('input');

    $datetimeField.val(null);
  }

  $scope.find('input.single-datetime').daterangepicker({
    timePicker: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    locale: {
        cancelLabel: 'Clear'
    }
  });

  $scope.find('input.single-datetime').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY hh:mm Z'));
  });

  $scope.find('input.single-datetime').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
  });

  $scope.find(".date-input button.clear").click(clearDate);
}

$(document).on('turbolinks:load', function() {
  enableDatetimeFields($('body'));
});

$(document).on('sprinkles:update', function(event) {
  enableDatetimeFields($(event.target));
});
