import Tribute from 'tributejs';
import Trix from 'account/trix-bundle';

// only show the editor tool bar when the user is editing the field.
// inspired by https://github.com/basecamp/trix/issues/343 and `app/assets/stylesheets/account/fields/trix_editor.scss`

$(document).on('trix-initialize', function(e) {
  let trix = e.target;

  initImageAttachmentToolbar(trix, cloudinaryConfig);
  initVideoAttachmentToolbar(trix);

  addEventListener("trix-focus", updateTrixToolbarVisability);
  addEventListener("trix-blur", updateTrixToolbarVisability);

  initializeTribute();
});

function updateTrixToolbarVisability() {
  $("trix-editor").each(function (index, editorElement) {
    var toolbarElement = editorElement.toolbarElement;
    if (editorElement == document.activeElement) {
      $(toolbarElement).addClass('visible');
    } else {
      // don't hide the toolbar if we've unfocused to focus on the link dialog.
      if (!toolbarElement.contains(document.activeElement)) {
        $(toolbarElement).removeClass('visible');
      }
    }
  });
}

function initializeTribute() {
  $('trix-editor').each(function(index) {
    var editor = this.editor;

    var mentionConfig = {
      trigger: '@',
      values: JSON.parse(editor.element.dataset.mentions),
      selectTemplate: function (item) {
        item = item.original;
        return '<a href="' + item.protocol + '://' + item.model + '/' + item.id + '">' + item.label + '</a>';
      },
      menuItemTemplate: function (item) {
        return '<img src="' + item.original.photo + '" /> ' + item.string;
      },
      requireLeadingSpace: true,
      replaceTextSuffix: ''
    }

    var topicConfig = {
      trigger: '#',
      values: JSON.parse(editor.element.dataset.topics),
      selectTemplate: function (item) {
        item = item.original;
        return '<a href="' + item.protocol + '://' + item.model + '/' + item.id + '">' + item.label + '</a>';
      },
      menuItemTemplate: function (item) {
        return '<img src="' + item.original.photo + '" /> ' + item.string;
      },
      requireLeadingSpace: true,
      replaceTextSuffix: ''
    }

    var tribute = new Tribute({
      collection: [topicConfig, mentionConfig],
    });

    tribute.attach(this);

    // editor.element.addEventListener('keydown', function(event) {
    //   if (event.keyCode == 13) {
    //     // TODO there has got to be a better fix than this.
    //     // apparently old, removed-from-the-page trix editors are colliding with new ones.
    //     if ($(editor.element).closest('body').length == 0) {
    //       return;
    //     }
    //
    //     if (tribute.isActive) {
    //       return;
    //     }
    //
    //     if (editor.element.dataset.enter == 'submit') {
    //       event.preventDefault();
    //       return $(editor.element).closest('form').submit();
    //     }
    //   }
    // });

    // tribute.range.pasteHtml = function(html, startPos, endPos) {
    //   for (var i = endPos; i > startPos; i--) {
    //     editor.deleteInDirection("backward")
    //   }
    //   editor.insertHTML(html)
    // }

  })
}

function initImageAttachmentToolbar(trix, options) {
  let toolBar = trix.toolbarElement;

  let span = document.createElement('span');
  span.setAttribute('class', 'cloudinary-field trix-button-group trix-button-group--file-tools');
  span.setAttribute('data-cloud-name', options.cloud_name);
  span.setAttribute('data-api-key', options.api_key);
  span.setAttribute('data-url-format', options.url_format);

  let hiddenField = document.createElement('input');
  hiddenField.setAttribute('type', 'hidden');

  // Creation of the button
  let button = document.createElement("button");
  button.setAttribute("type", "button");
  button.setAttribute("class", "attach-image trix-button trix-button--icon trix-button--icon-attachment");
  button.setAttribute("data-trix-action", "x-attach");
  button.setAttribute("title", "Attach an image");
  button.setAttribute("tabindex", "-1");
  button.innerText = "Attach an image";

  span.append(hiddenField);
  span.append(button);

  toolBar.querySelector('.trix-button-group--file-tools').remove();

  // Attachment of the button to the toolBar
  toolBar.querySelector('.trix-button-group--block-tools').after(span);

  $(span).on('image:selected', function(e, data) {
    createAttachment(data);
  });

  async function createAttachment(data) {
    let url = data.secure_url,
        filename = data.original_filename + '.' + data.format,
        format = data.format;

    let response = await fetch(url);
    let blob = await response.blob();
    let metadata = {
      type: 'image/' + format
    };
    let file = new File([blob], filename, metadata);

    let attachment = Trix.Attachment.attachmentForFile(file);
    attachment.setAttributes({
      url: url,
      href: url
    });

    trix.editor.insertAttachment(attachment);
  }
}

function initVideoAttachmentToolbar(trix) {
  let toolBar = trix.toolbarElement;

  let span = document.createElement('span');
  span.setAttribute('class', 'trix-button-group trix-button-group--file-tools');

  // Creation of the button
  const button = document.createElement("button");
  button.setAttribute("type", "button");
  button.setAttribute("class", "attach trix-button trix-button--icon trix-button--icon-video");
  button.setAttribute("data-trix-action", "x-attach");
  button.setAttribute("title", "Attach a file");
  button.setAttribute("tabindex", "-1");
  button.innerText = "Attach a video";

  span.append(button);

  // Attachment of the button to the toolBar
  toolBar.querySelector('.trix-button-group--block-tools').after(span);

  let dialog = document.createElement('div');
  dialog.setAttribute('class', 'trix-dialog trix-dialog--video');
  dialog.setAttribute('data-trix-dialog', 'href')
  dialog.innerHTML = "<div class='trix-dialog--video-fields'>" +
    "<label for='trix-dialog--video-embed'>Input Video Embed Code:</label>" +
    "<textarea id='trix-dialog--video-embed' name='embed'></textarea>" +
    "<button class='trix-dialog--video-submit'>Embed</button>" +
    "</div>";

  toolBar.querySelector('.trix-dialogs').append(dialog);

  $(button).on('click', function() {
    $('.trix-dialog--video').toggle();
  });

  $('.trix-dialog--video button').on('click', function(e) {
    e.preventDefault();

    const embed = $('#trix-dialog--video-embed').val();
    if (embed.length > 0) createAttachment(embed);

    $('.trix-dialog--video').toggle();
    $('#trix-dialog--video-embed').val('');
  })

  function createAttachment(data) {
    const attachment = new Trix.Attachment({ content: data });
    trix.editor.insertAttachment(attachment);
  }
}
