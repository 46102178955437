import 'bootstrap';
import 'owl.carousel';
import 'sharer.js/sharer.min.js';
import './share-modal'

var $sections;
var $sidebarSection;
var $content;
var $panel;
var $navSpy;
var $secondaryNav;
var $sidebarSectionTitle;
var $header = $('.header');
var headerHeight = $('header').outerHeight();
var secondaryNavHeight;
var $secondaryAnchorsNav;
var spySectionOffsetTop;

function refreshConstants() {
  $sections = $('.js-scroll-section');
  $sidebarSection = $('.main__inner');
  $sidebarSectionTitle = $sidebarSection.find('.js-sticky-panel-aim');
  $content = $('.js-content');
  $panel = $('#sticky-panel');
  $navSpy = $('.nav-spy');
  $header = $('.header');
  $secondaryNav = $('.secondary-nav');
  $secondaryAnchorsNav = $secondaryNav.find('.secondary-nav__part--main');
  headerHeight = $('header').outerHeight();
  secondaryNavHeight = $(window).width() < 769 ? $secondaryAnchorsNav.outerHeight() : $secondaryNav.outerHeight();
  spySectionOffsetTop = 20;
}

function enableMayku($scope) {
    refreshConstants();

    var pageReload = false;

    // there are some things we only need to do on a full page reload.
    if (!$scope) {
        pageReload = true;
        $scope = $('body');
    }

    // Slider fluid
    $scope.find('.slider-fluid .slider__slides').owlCarousel({
        items: 1,
        startPosition: 2,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        margin: 6,
        dots: false,
        nav: true,
        navContainerClass: 'slider__actions',
        navClass: ['slider__btn slider__btn-prev','slider__btn slider__btn-next'],
        navText: [],
        responsive:{
          0:{
            margin: 0,
            stagePadding: 0,
            nav: false,
            dots: true
          },
          768:{
            stagePadding: 100
          },
          1200:{
            stagePadding: 225
          }
        },
        onInitialized: function() {
            const $owl = $(this.$element);
            const $activeDot = $owl.find('.owl-dots .active');

            $activeDot.prev().addClass('prev-dot')
            $activeDot.next().addClass('next-dot')
        },
        onTranslate: function() {
            const $owl = $(this.$element);
            const $activeDot = $owl.find('.owl-dots .active');

            $owl.find('.owl-dot').removeClass('prev-dot next-dot');

            $activeDot.prev().addClass('prev-dot')
            $activeDot.next().addClass('next-dot')
        }
    });

    // Slider videos
    $scope.find('.slider-videos .slider__slides').owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        margin: 19,
        dots: false,
        nav: true,
        navContainerClass: 'slider__actions',
        navClass: ['slider__btn slider__btn-prev','slider__btn slider__btn-next'],
        navText: [],
        responsive:{
          0:{
            items: 1,
            margin: 10
          }
        }
    });

  /*

    // Load popup with AJAX
    let ajaxURL = '';

    $('.modal').on('show.bs.modal', function (event) {
        const $modal = $(this);
        const url = $(event.relatedTarget).attr('href');
        const $ajaxContainer = $modal.find('.modal-content');

        $header.addClass('fixed');

        if (ajaxURL !== url) {
            $ajaxContainer.empty();

            $.ajax({
        url: url,
        error: function() {
            console.error(arguments);
        },
        success: function (response) {
          $ajaxContainer.append(response);

          ajaxURL = url;
        }
      });
        }
    }).on('hidden.bs.modal', function(event) {
        $header.removeClass('fixed');
    });
  */

    // Center current nav link with offset
    setPosition();
    enableNavSpy();

    // Scroll to section
    $navSpy.on('click', 'a', function() {
        var $el = $(this);
    var id = $el.attr('href');
    var combinedOffsets = headerHeight + secondaryNavHeight + spySectionOffsetTop

        $('html, body').animate({
            scrollTop: $(id).offset().top - combinedOffsets + 1 + 'px'
        }, 500);

        return false;
    });

  $(document).on('click', '.js-copy-url', function() {
    var btnLabel = $(this).find('span');
    var defaultText = btnLabel.text();

    copyToClipboard($(this).attr('data-url'));
    btnLabel.text('Copied!');

    setTimeout(function() {
      btnLabel.text(defaultText)
    }, 2000)
  });

  if ($sidebarSection && $sidebarSection.length) {
    // fixResourcesPanel();
    // fixSecondaryNav();
  }

  if ($(window).width() < 769) {
    placeCurrentLinkText();
  }
}

// Place current link text
function placeCurrentLinkText() {
  var $currentLink = $('.nav-alt ul li.current').find('a');
  var $currentLinkText = $currentLink.text();
  var $currentPageBtn = $('.nav-alt > .btn');

  $currentPageBtn.html($currentLinkText);
}

function fixSecondaryNav() {
  var winHeight = window.innerHeight;
  var currentTop = $(window).scrollTop();
  var sidebarSectionTitleTop = $sidebarSectionTitle.offset() ? $sidebarSectionTitle.offset().top : 0;
  var contentTop = $content.offset() ? $content.offset().top : 0;
  var contentHeight = $content.outerHeight();
  var isContentBottom = contentTop + contentHeight < currentTop + headerHeight + secondaryNavHeight;
  var isScrolledPastTitle = currentTop >= sidebarSectionTitleTop - headerHeight

  $secondaryNav.toggleClass('secondary-nav--visible', isScrolledPastTitle && !isContentBottom)
}

function fixResourcesPanel() {
  if ($(window).width() <= 768) return

  var winHeight = window.innerHeight;
  var currentTop = $(window).scrollTop();
  var panelHeight = $panel.outerHeight();
  var sidebarSectionTitleTop = $sidebarSectionTitle.offset() ? $sidebarSectionTitle.offset().top : 0;
  var contentTop = $content.offset() ? $content.offset().top : 0;
  var contentHeight = $content.outerHeight();
  var isContentBottom = contentTop + contentHeight < currentTop + panelHeight + secondaryNavHeight + headerHeight + 40;

  if (currentTop >= sidebarSectionTitleTop - headerHeight) {
    $panel.addClass('panel--fixed');
  } else {
    $panel.removeClass('panel--fixed');
  }

  $panel.toggleClass('panel--absolute', isContentBottom);
}

$(window).on('scroll', function() {
  if ($sidebarSection && $sidebarSection.length) {
    // fixResourcesPanel();
    // fixSecondaryNav();
  }
});

function setPosition() {
  if (!$navSpy) return

  var $activeMenuItem = $navSpy.find('li.current')

  if ($activeMenuItem.length) {
    var $currentLinkOffset = $activeMenuItem.offset().left;
    var myScrollPos = $currentLinkOffset + $navSpy.scrollLeft();
    $navSpy.scrollLeft(myScrollPos);
  }
}

// Nav spy functionality
function enableNavSpy() {
  if ($sections) {
    var $this = $(this);
    var winHeight = $(window).height();
    var currentPosition = $this.scrollTop();

    $sections.each(function() {
      var $this = $(this);
      var combinedOffsets = headerHeight + secondaryNavHeight + spySectionOffsetTop;
      var top = $this.offset().top - combinedOffsets;

      if (currentPosition > top) {
        $navSpy.find('a').parent('li').removeClass('current');
        $navSpy.find('a[href="#' + $this.attr('id') + '"]').parent('li').addClass('current');
      }
    });
  }
}

function copyToClipboard(str) {
  var el = document.createElement('textarea');
  var body = document.body;

  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  body.appendChild(el);
  el.select();
  document.execCommand('copy');
  body.removeChild(el);
};

$(document).on('turbolinks:load', function() {
  if ($('body').hasClass('public')) {
    console.log("👷‍♀️ Mayku: Enabling on <body> after a Turbolinks load.")
    // TODO this shouldn't require a todo.
    setTimeout(function() {
      enableMayku();
    }, 100);
  }
});

// $(document).on('sprinkles:update', function(event) {
//   if ($('body').hasClass('public')) {
//     console.log("👷‍♀️ Makyu: Enabling on the following element after a Sprinkles content update:")
//     enableMayku($(event.target));
//   }
// })

$(window).on('resize', setPosition);
$(window).on('scroll', function() {
  enableNavSpy.call(this);
  setPosition();
});
